import { GetStaticProps } from 'next'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Head from 'components/Head'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import SEOContent from 'components/SEOContent'
import company from 'config/company.json'
import boatValues from 'config/values.json'
import promos from 'config/promos'
import { initHeaders } from 'utils/request/headers'
import { loadTranslations } from 'utils/request/i18n'
import {
  NavigationProvider,
  useCompanySchema,
  useLocaleState,
  listBanners,
  listDestinationAreas,
  listHotDestinations,
  listDestinationTerritoryHubs,
  useTranslation,
  // NavbarBanner,
  HomeHero,
  HomeBestPrices,
  HomeDestinations,
  HomeEnquiryAnchor,
  HomeContent,
  HomeBlog,
  SeoDestinationMap,
  getHomepageTerritoryMap
} from '@sailogy/shipwright'
import {
  DestinationTerritoryHubListed,
  DestinationListed,
  BannerInfo,
  ParsedParagraph,
  SeoDestinationHomepage
} from '@sailogy/types'
import { K, i18nPath } from 'i18n/home'

const NavbarBanner = dynamic(
  () => import('@sailogy/components/Banner'),
  { ssr: true }
)
// const HomeDestinations = dynamic(
//   () => import('@sailogy/containers/Home/Destinations'),
//   { ssr: false },
// )
// const HomeEnquiryAnchor = dynamic(
//   () => import('@sailogy/containers/Home/EnquiryAnchor'),
//   { ssr: false },
// )
// const HomeContent = dynamic(
//   () => import('@sailogy/containers/Home/Content'),
//   { ssr: false },
// )
// const HomeBlog = dynamic(
//   () => import('@sailogy/containers/Home/Blog'),
//   { ssr: false },
// )
const PaidLandingEnquiry = dynamic(
  () => import('@sailogy/containers/Enquiry/Landing'),
  { ssr: false }
)

interface Props {
    banners: BannerInfo[]
    areas: DestinationListed[]
    hubs: DestinationTerritoryHubListed[]
    destinations: DestinationListed[]
    seoDestinations?: SeoDestinationHomepage
}


const HomePage: React.FC<Props> = ({
  areas: ssrAreas, hubs: ssrHubs,
  destinations: ssrDestinations, banners: ssrBanners, seoDestinations
}) => {
  const { locale } = useRouter()
  const t = useTranslation<K>()
  const structuredData = useCompanySchema(company)
  const enquiryId = 'enquiry'
  const [banners] = useLocaleState<BannerInfo[]>(
    ssrBanners, async (signal: AbortSignal) => (
      listBanners({ position: 'homepage' } as any, { signal })
        .then(({ results }) => results)
    )
  )
  const [areas] = useLocaleState<DestinationListed[]>(
    ssrAreas, async (signal: AbortSignal) => (
      listDestinationAreas({ hot: true }, { signal })
        .then(({ results }) => results)
    )
  )
  const [hubs] = useLocaleState<DestinationTerritoryHubListed[]>(
    ssrHubs, async (signal: AbortSignal) => (
      listDestinationTerritoryHubs({ signal })
        .then(({ results }) => results)
    )
  )
  const [destinations] = useLocaleState<DestinationListed[]>(
    ssrDestinations, async (signal: AbortSignal) => (
      listHotDestinations(undefined, { signal })
        .then(({ results }) => results)
    )
  )
  const image = useMemo(() => (
    promos.black_friday ? 'https://sailogy-pictures-public.imgix.net/images/bg/bw_bg.jpg'
      : 'https://sailogy-pictures-public.imgix.net/images/bg/hendrik-cornelissen-b_oRl_WOMVM-unsplash.jpg'
  ), [promos.black_friday])
  const logoType = useMemo(() => (
    promos.black_friday ? 'https://sailogy-pictures-public.imgix.net/images/bg/bw_lt.svg' : undefined
  ), [promos.black_friday])

  const content: ParsedParagraph[] = [{
    title: t('Your dedicated team of experts'),
    content: (
      <>
        <p>{t('What sets {SITE_NAME} apart from other online boat rental platforms is its dedicated team of multilingual experts. The team is ongoingly trained to deliver the best support you can get and is regularly in touch with the charter companies we partner with.', { SITE_NAME: company.name })}</p>
        <h3>{t('Professional and reliable charter companies')}</h3>
        <p>{t('We select only the most professional sailing charters in the market that can guarantee a reliable and sound experience. We run regular quality checks on the sailing charters we choose to partner with and promptly channel any feedback we receive to make sure you will experience only the absolute best.')}</p>
      </>
    ),
    image: 'https://sailogy-pictures-public.imgix.net/img/shutterstock_1167345937.jpg',
    image_alignment: 'right',
    cta_label: t('Ask for a free quotation'),
    cta_anchor: enquiryId
  }, {
    title: t('Sailing Holidays worldwide'),
    content:
  <p>
    {t('{SITE_NAME} is a world leading online yachtcharter booking platform. At {SITE_NAME}.com you can individually customise and plan your sailing vacation. We offer both bareboat and skippered charters on over %(boat_count)s sailing yachts, Motor yachts, catamarans and gulets. Check out our fantastic offers for economy, comfort and luxury yacht charters and book your next vacation online!', {
      SITE_NAME: company.name,
      boat_count: boatValues.boat_count
    })}
  </p>,
    image: 'https://sailogy-pictures-public.imgix.net/img/shutterstock_649054294.jpg',
    image_height: 260,
    image_alignment: 'left'
  }]
  if (locale === 'de') {
    content.push({
      title: 'Lieben Sie Segelurlaub?',
      content: <SEOContent />
    })
  }

  return (
    <NavigationProvider path="/">
      <Head
        title={t('Online Yacht Charter for the Best Sailing Holidays')}
        description={t('Yacht charter in over %(destination_count)s sailing holidays destinations. Choose the best yacht to rent %(boat_count)s certified sailing boats, catamarans, motorboats and gulets. You can hire a skippered or bareboat. Real time availability. Book your sailing week now!', {
          destination_count: boatValues.destination_count,
          boat_count: boatValues.boat_count
        })}
        schema={structuredData}
      />
      {(banners && banners.length > 0) && (
      <NavbarBanner
        title={banners[0].title}
        body={banners[0].body}
        base64
        image={banners[0].image}
        imageMobile={banners[0].mobile_image}
        href={banners[0].action_url}
        lightText={banners[0].light_text}
        backgroundColor={banners[0].hex_color}
        deadline={banners[0].countdown_deadline}
        countdownTitle={banners[0].countdown_running_title}
        countdownBody={banners[0].countdown_running_body}
        countdownHref={banners[0].countdown_running_action_url}
        cta={banners[0].cta_button}
      />
      )}
      <div style={{ position: 'relative' }}>
        <Navbar overlay />
        <HomeHero
          title={t('Find your perfect sailing holiday')}
          subtitle={t('Hire a sailing boat, catamaran, powerboat. Skippered or bareboat, anyplace in the world')}
          excerpt={t('Search among %(boat_count)s certified boats worldwide, bareboat or skippered.', { boat_count: boatValues.boat_count })}
          image={image}
          logoType={logoType}
        />
      </div>
      {/* <HomepageBanner /> */}
      {hubs.length > 0 && <HomeBestPrices hubs={hubs} />}
      <HomeDestinations areas={areas} destinations={destinations} />
      <HomeEnquiryAnchor enquiryId={enquiryId} />
      <HomeContent paragraphs={content} />
      <HomeBlog magazine="Magister Navis" />
      <PaidLandingEnquiry anchor={enquiryId} />
      <SeoDestinationMap seoDestinations={seoDestinations} />
      <Footer />
    </NavigationProvider>
  )
}

// Build SSG pages for each language
export const getStaticProps: GetStaticProps<Props> = async (ctx) => {
  const [
    i18n,
    { results: banners },
    { results: areas },
    { results: destinations },
    { results: hubs }
  ] = await Promise.all([
    loadTranslations(ctx.locale!, i18nPath),
    listBanners({ position: 'homepage' } as any, initHeaders(ctx)),
    listDestinationAreas({ hot: true }, initHeaders(ctx)),
    listHotDestinations(undefined, initHeaders(ctx)),
    listDestinationTerritoryHubs(initHeaders(ctx))
  ])
  const seoDestinations = await getHomepageTerritoryMap(initHeaders(ctx))
  return {
    props: {
      ...ctx.params,
      i18n,
      i18nPath,
      banners,
      areas,
      hubs,
      destinations,
      seoDestinations
    },
    revalidate: 21600 // 6h
  }
}

export default HomePage
